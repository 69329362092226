<template>
  <div id="archive-page">
    <app-box width="large">
      <template #legend>Tasks</template>
      <template #title>Archive<span class="text-xs text-gray-500"><span class="mx-2 text-gray-400">|</span><router-link to="/tasks" class="underline hover:no-underline">{{ minimal ? 'Active' : 'Back to active tasks' }}</router-link></span></template>
      <template v-if="!minimal" #intro>
        <template v-if="cItems.length || showSearch">
          Here are the tasks you have completed previously. Hopefully you can just leave them here, but if you've made a mistake you can always unarchive them to bring them back to the active tasks list.
        </template>
        <template v-else>
          Here's where we show you the tasks you have completed, but it doesn't seem like you have any yet.
        </template>
      </template>
      <div :class="minimal ? ['pt-1.5 md:pt-0'] : []">
        <app-table v-if="cItems.length || showSearch" class="-mb-8 md:mb-0 -mx-5 md:mx-0" :class="minimal ? ['-mt-14 md:-mt-8'] : []">
          <app-thead>
            <app-tr>
              <app-th colspan="2">
                <div class="flex">
                  <div class="flex-grow">
                    {{ total }} Task{{ total !== 1 ? 's' : '' }}
                  </div>
                  <div class="-mr-2">
                    <template v-if="showSearch">
                      <input ref="searchInput" v-model="searchText" type="search" placeholder="Search..." class="p-0 leading-relaxed placeholder-gray-500 w-24 ml-1 text-gray-700 bg-gray-100 font-medium rounded border border-gray-400 hover:border-gray-500 hover:shadow-sm text-2xs px-1 focus:ring-0 focus:border-blue-400">
                    </template>
                    <button :disabled="searchText != ''" class="ml-1 bg-gray-200 font-medium rounded border border-gray-400 text-2xs px-1 focus:outline-none focus:border-blue-400" :class="searchText != '' ? 'text-gray-500 cursor-default' : 'text-gray-700 hover:border-gray-500 hover:shadow-sm'" @click="handleToggleSearch">{{ showSearch ? "Close" : "Search" }}</button>
                  </div>
                </div>
              </app-th>
            </app-tr>
          </app-thead>
          <app-tbody>
            <transition-group leave-active-class="transition-opacity duration-150" leave-from-class="opacity-100" leave-to-class="opacity-0">
              <tr v-for="item in cItems" :key="item.uuid">
                <app-td class="bg-gradient-to-r from-green-200 to-gray-200 lg:to-transparent">
                  <app-td-content>
                    <template #first><span class="text-green-800">{{ item.title }}</span></template>
                    <template #second>
                      <span class="text-green-700">{{ item.projectName }}
                        <template v-if="item.recurringText">
                          <span class="mx-1 text-green-600">|</span>{{ item.recurringText }}
                        </template>
                        <span class="hidden md:inline"><span class="mx-1 text-green-600">|</span>{{ item.recurringDayOfWeek || item.recurringDayOfMonth ? 'Last Completed' : 'Completed' }} {{ formatDate(item.doneOn) }}</span>
                      </span>
                      <template v-if="item.link">
                        <span class="mx-1 text-green-600">|</span><a :href="item.link" target="_blank" class="text-green-700 underline hover:no-underline">{{ item.linkTitle }}</a>
                      </template>
                    </template>
                  </app-td-content>
                </app-td>
                <app-td class="w-1 bg-gray-200 lg:bg-transparent">
                  <app-dropdown :options="item.options" @click="handleChoice(item.uuid, $event)" />
                </app-td>
              </tr>
            </transition-group>
          </app-tbody>
        </app-table>
      </div>
      <p id="endOfPage" class="pt-8 text-xl md:text-2xl leading-tight text-center animate-pulse" :class="loadingMore ? 'text-gray-700' : 'text-transparent'">Loading...</p>
    </app-box>
  </div>
</template>

<script>
import {
  computed,
  onBeforeMount,
  onUnmounted,
  ref,
  nextTick,
  watch,
} from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Util as U } from "@/util";
export default {
  name: "Archive",
  setup() {
    let searchTimeout;
    const aid = window.localStorage.getItem("aid");
    const pageSize = aid === "2147538260" ? 20 : 100;
    const searchInput = ref(null);
    const searchText = ref("");
    const showSearch = ref(false);
    const projects = ref([]);
    const items = ref([]);
    const pageNumber = ref(1);
    const total = ref(0);
    const loadingMore = ref(false);
    const router = useRouter();
    const store = useStore();
    const loggedIn = computed(() => store.getters.loggedIn);
    const minimal = computed(() => store.state.minimal);
    const scrollObserver = new IntersectionObserver(async ([entry]) => {
      if (entry && entry.isIntersecting) {
        if (items.value.length / pageNumber.value != pageSize) return;
        pageNumber.value++;
        loadingMore.value = true;
        var params = new URLSearchParams();
        params.append("done", true);
        params.append("pageNumber", pageNumber.value);
        params.append("pageSize", pageSize);
        if (searchText.value) {
          params.append("searchText", searchText.value);
        }
        const qs = params.toString();
        const url = `api/items${qs.length ? "?" : ""}${qs}`;
        const results = await U.api({ url, load: false });
        items.value.push(...results.items);
        loadingMore.value = false;
      }
    });

    onBeforeMount(async () => {
      if (U.redirect(loggedIn.value)) return router.push("/");
      var params = new URLSearchParams();
      params.append("done", true);
      params.append("pageNumber", 1);
      params.append("pageSize", pageSize);
      const qs = params.toString();
      const itemsUrl = `api/items${qs.length ? "?" : ""}${qs}`;
      const results = await U.apiAll([
        { url: itemsUrl },
        { url: "api/projects" },
      ]);
      total.value = results[0].total;
      items.value = results[0].items;
      projects.value = results[1].projects;
      await nextTick();
      scrollObserver.observe(document.querySelector("#endOfPage"));
    });

    onUnmounted(() => {
      scrollObserver.disconnect();
    });

    const cItems = computed(() => {
      if (!items.value.length || !projects.value.length) return [];
      return [...items.value]
        .sort((a, b) => {
          const aDate = new Date(a.doneOn).getTime();
          const bDate = new Date(b.doneOn).getTime();
          return bDate - aDate;
        })
        .filter(U.projectInFocus)
        .filter(U.doneBeforeToday)
        .map((i) => {
          const project = projects.value.find((p) => p.uuid === i.projectUuid);
          i.projectName = project.name;
          i.linkTitle = i.link ? U.domainFromUrl(i.link) : "";
          i.options = ["Unarchive", "Delete"];
          if (i.recurringDayOfWeek || i.recurringDayOfMonth) {
            i.recurringText = i.recurringDayOfWeek
              ? `Weekly (${U.dayOfWeek(i.recurringDayOfWeek)})`
              : `Monthly (${U.daySuffix(parseInt(i.recurringDayOfMonth))})`;
          }
          return i;
        });
    });

    const handleChoice = (uuid, option) => {
      items.value =
        option === "Delete"
          ? U.delete("item", items.value, uuid)
          : U.patch("item", items.value, uuid, { doneOn: "" });
    };

    const handleToggleSearch = async () => {
      showSearch.value = !showSearch.value;
      if (showSearch.value) {
        await nextTick();
        searchInput.value.focus();
      }
    };

    watch(
      () => searchText.value,
      (val) => {
        clearTimeout(searchTimeout);
        searchTimeout = setTimeout(async () => {
          pageNumber.value = 1;
          var params = new URLSearchParams();
          params.append("done", true);
          params.append("pageNumber", 1);
          params.append("pageSize", pageSize);
          if (val) {
            params.append("searchText", val);
          }
          const qs = params.toString();
          const url = `api/items${qs.length ? "?" : ""}${qs}`;
          const results = await U.api({ url });
          total.value = results.total;
          items.value = results.items;
        }, 250);
      }
    );

    return {
      searchText,
      showSearch,
      minimal,
      cItems,
      total,
      loadingMore,
      searchInput,
      handleChoice,
      handleToggleSearch,
      formatDate: U.formatDate,
    };
  },
};
</script>
